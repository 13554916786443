<template>
    <div>
        <div class="header-top">
            <div class="top-content">
               <div class="website_title" @click="$router.push('/')">
					<img class="mling" :src="$img(siteInfo.logo)" />
					{{siteInfo.title}}</div>
			   <div class="top_right">
				   <router-link class="item" to="/member/index">我的</router-link>
				   <a class="item" @click="openKeFu()">客服</a>
				   <a class="item upload_app" >下载APP<img v-if="appQrcode" class="appQrcode" :src="appQrcode" /></a>
				   <a class="item" @click="openPublish()">发布</a>
				   <div class="login_action">
					   <router-link class="login" v-if="!logined" to="/login">登录</router-link>
					   <div v-if="logined" class="member-info">
						   <img class="headimg" :src="$img(member.headimg ? member.headimg : defaultHeadImage)" @error="member.headimg = defaultHeadImage" />
					       <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
							|
					       <span class="login_out" @click="logout">退出</span>
					   </div>
						{{logined?'':'|'}}
					   <router-link class="register" v-if="!logined" to="/login" >注册</router-link>
				   </div>
			   </div>
            </div>
        </div>
		<servicerMessage ref="servicerMessage" class="kefu"></servicerMessage>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { getToken } from "@/utils/auth"
	import servicerMessage from "@/components/message/servicerMessage"
    export default {
        props: {},
        data() {
            return {}
        },
        created() {
			this.$store.dispatch("site/milingQrCodes")
			this.$store.dispatch("site/siteInfo")
			this.$store.dispatch("site/qrCodes")
			this.$store.dispatch("member/member_detail")
			this.$store.dispatch("site/defaultFiles")
			this.$store.dispatch("site/addons")
			
		},
        mounted() {
			if(localStorage.getItem('isKefuTop') && this.token){
				this.$refs.servicerMessage.show();
				localStorage.removeItem('isKefuTop');
			}
		},
        watch: {},
        methods: {
			openPublish(){
				if (this.token) {
					this.$router.pushToTab('/cms/publish_info?mode=1')
				} else {
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
					setTimeout(()=>{
						this.$router.push({path: '/login', query: {redirect: '/cms/publish_info?mode=1'}});
					},800)
				}
			},
			openKeFu(){
				if (this.token) {
					this.$refs.servicerMessage.show()
				} else {
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
					setTimeout(()=>{
						localStorage.setItem("isKefuTop",true);
						this.$router.push({path: '/login', query: {redirect: this.$route.fullPath}});
					},800)
				}
			},
            logout() {
                this.$store.dispatch("member/logout").then(res=>{
					let arr = ['/member/index','/cms/publish_info'];
					if(arr.indexOf(this.$route.path)!=-1){
						this.$router.push('/');
					}
				});
            }
        },
        components: {
			servicerMessage
		},
        computed: {
            ...mapGetters(["wapQrcode", "member", "addonIsExit", "city","token","siteInfo","milingQrcode","defaultHeadImage"]),
            qrcode: function() {
                return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
            },
			appQrcode: function() {
			    return this.milingQrcode === "" ? "" : this.milingQrcode.qrcode
			},
            logined: function() {
                return this.member !== undefined && this.member !== "" && this.member !== {}
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-top {
        width: 100%;
        background-color: #E0E2EA;
		.top-content{
			width: $width;
			height: 100%;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 9px 0;
			.website_title{
				color: #222B33;
				font-size: 15px;
				line-height: 18px;
				font-weight: 600;
				cursor: pointer;
				display: flex;
				align-items: center;
				.mling{
					height: 18px;
					display: block;
					margin-right: 10px;
				}
			}
			.top_right{
				display: flex;
				align-items: center;
				.appQrcode{
					display: none;
					position: absolute;
					left: 50%;
					top: 135%;
					width: 100px;
					height: 100px;
					transform: translateX(-50%);
				}
				.upload_app:hover .appQrcode{
					display: block;
				}
				.item{
					position: relative;
					display: block;
					margin-right: 10px;
					padding: 5px 30px;
					font-size: 15px;
					line-height: 18px;
					color: #222B33;
					cursor: pointer;
					&:hover{
						color: $base-color;
					}
				}
				.login_out{
					cursor: pointer;
				}
				.login_action{
					display: flex;
					align-items: center;
					&.member-info {
					    margin-left: 0;
						display: flex;
						align-items: center;
						
					    span:first-child {
					        margin-right: 10px;
					    }
					}
					span:hover {
					    color: $base-color;
					}
					.login,.register{
						display: block;
						font-size: 15px;
						line-height: 18px;
						color: #222B33;
						&:hover{
							color: $base-color;
						}
					}
					.member-info{
						display: flex;
						align-items: center;
						color: #222B33;
						font-size: 15px;
						line-height: 18px;
						a{
							margin-right: 5px;
						}
						.login_out{
							margin-left: 5px;
						}
						.headimg{
							width:18px;
							height:18px;
							display: block;
							border-radius: 50%;
							margin-right: 5px;
							object-fit: cover;
						}
					}
					.login{
						margin-right: 5px;
					}
					.register{
						margin-left: 5px;
					}
				}
			}
		}
   }
    .mobile-qrcode {
        padding: 10px 0;
    }
	.router-link-active{
		color:$base-color;
	}
</style>
