<template>
    <div class="footer">
		<div class="top">
			<div class="left">
				<div class="kefu" @click="openKeFu()">
					<div class="iconfont1 icon-kefu"></div>客服
				</div>
				<div class="phone">
					<div class="iconfont1 icon-dianhua"></div>{{siteInfo.web_phone}}
				</div>
				<div class="link">{{siteInfo.web_email}}</div>
			</div>
			<div class="middle">
				<div class="shu" v-for="(item,index) in helpList" :key="index">
					<div class="item" v-for="(cItem,cIndex) in item.child_list" :key="cIndex" @click="clickToHelpDetail(cItem.id)">{{cItem.title}}</div>
				</div>
			</div>
			<div class="right">
				<div class="item" v-if="wapQrcode">
					<img :src="$util.img(wapQrcode.path.wechat.img)" alt="">
					公众号
				</div>
				<div class="item" v-if="wapQrcode">
					<img :src="$util.img(wapQrcode.path.weapp.img)" alt="">
					小程序
				</div>
				<div class="item" v-if="milingQrcode">
					<img :src="milingQrcode.qrcode" alt="">
					APP下载
				</div>
			</div>
		</div>
		<div class="bottom">
			<div class="item tit" v-for="(item,index) in linkList" :key="index" @click="linkUrl(item.link_url, item.is_blank)">{{item.link_title}}</div>
			<div class="item" v-if="copy_right">
				<a class="tit" target="_blank" href="https://beian.miit.gov.cn">{{copy_right.icp}}</a>
				<div class="tit">{{copy_right.copyright_link}}</div>
			</div>
		</div>
		<servicerMessage ref="servicerMessage" class="kefu"></servicerMessage>
	</div>
</template>

<script>
	import servicerMessage from "@/components/message/servicerMessage"
    import { copyRight, shopServiceLists, friendlyLink } from "@/api/website"
    import { mapGetters } from "vuex"
    import { helpList } from "@/api/cms/help"

    export default {
		components: {
			servicerMessage
		},
        props: {},
        data() {
            return {
                shopServiceList: [],
                linkList: [],
                helpList: [],
                ishide: false,
                activeName: "first",
				copy_right:{},
            }
        },
        computed: {
            ...mapGetters(["siteInfo","wapQrcode","milingQrcode","token"])
        },
        created() {
            this.getShopServiceLists()
            this.link()
            this.getHelpList()
			this.getCopyRight();
        },
        mounted() {},
        watch: {},
        methods: {
			openKeFu(){
				if (this.token) {
					this.$refs.servicerMessage.show()
				} else {
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
					setTimeout(()=>{
						localStorage.setItem("isKefuTop",true);
						this.$router.push({path: '/login', query: {redirect: this.$route.fullPath}});
					},800)
				}
			},
			getCopyRight(){
				copyRight()
				.then(res=>{
					if(res.code>=0){
						this.copy_right = res.data;
					}
				})
			},
            getShopServiceLists() {
                shopServiceLists({}).then(res => {
                    if (res.code == 0 && res.data) {
                        this.shopServiceList = res.data
                    }
                })
            },
            link() {
                friendlyLink({})
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            this.linkList = res.data.list
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
            linkUrl(url, target) {
                if (!url) return
                if (url.indexOf("http") == -1) {
                    if (target) this.$router.pushToTab({ path: url })
                    else this.$router.push({ path: url })
                } else {
                    if (target) window.open(url)
                    else window.location.href = url
                }
            },
            /**
             * 获取帮助列表
             */
            getHelpList() {
                helpList()
                    .then(res => {
                        if (res.code == 0 && res.data) {
                            var arr = [];
                            arr = res.data.slice(0, 4)

                            for (let i=0; i<arr.length; i++) {
                                arr[i].child_list = arr[i].child_list.slice(0, 4);
                            }

                            this.helpList = arr
                        }
                    })
                    .catch(err => {})
            },
            /**
             * 图片加载失败
             */
            imageError() {
                this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
            },
            /**
             * 跳转到帮助列表
             */
            clickToHelp(id) {
                this.$router.push("/cms/help/listother-" + id)
            },
            /**
             * 跳转到帮助详情
             */
            clickToHelpDetail(id) {
                this.$router.push("/cms/help-" + id)
            }
        },
    }
</script>


<style scoped lang="scss">
	.footer{
		width: $width;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		.top{
			display: flex;
			align-items: center;
			border:1px solid rgba(48, 55, 61, 0.20);
			border-left: none;
			border-right: none;
			padding: 20px 0;
			.left{
				width: 250px;
				padding-right: 70px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				align-items: center;
				.link{
					color: #30373D;
					font-size: 13px;
					line-height: 15px;
				}
				.phone{
					display: flex;
					align-items: center;
					color: #30373D;
					font-size: 19px;
					line-height: 23px;
					font-weight: 600;
					margin-bottom: 9px;
					.iconfont1{
						font-weight: normal;
						margin-right: 5px;
						color: #30373D;
						padding: 5px;
						background-color: rgba(48, 55, 61, 0.20);
						border-radius: 50%;
						font-size: 12px;
						line-height: 1;
					}
				}
				.kefu{
					cursor: pointer;
					color: #fff;
					font-size: 15px;
					line-height: 18px;
					background-color: #30373D;
					border-radius: 30px;
					padding: 11px 32px;
					display: flex;
					align-items: center;
					margin-bottom: 19px;
					.iconfont1{
						color: #fff;
						font-size: 17px;
						margin-right: 6px;
					}
				}
			}
			.middle{
				display: flex;
				align-items: flex-start;
				padding: 10px 40px 10px 30px;
				border-left: 1px solid rgba(48, 55, 61, 0.20);
				border-right: 1px solid rgba(48, 55, 61, 0.20);
				.shu{
					margin-right: 30px;
					&:last-child{
						margin-right: 0;
					}
					.item{
						color: rgba(48, 55, 61, 0.60);
						font-size: 15px;
						line-height: 18px;
						margin-bottom: 30px;
						white-space: nowrap;
						cursor: pointer;
						&:hover{
							color: #FF3300;
						}
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
			.right{
				display: flex;
				align-items: center;
				flex:1;
				padding: 10px 0 10px 63px;
				.item{
					display: flex;
					flex-direction: column;
					align-items: center;
					color: #30373D;
					font-size: 15px;
					line-height: 18px;
					margin-right: 30px;
					&:last-child{
						margin-right: 0;
					}
					img{
						width: 135px;
						min-width:135px;
						height: 135px;
						display: block;
						border-radius: 10px;
						margin-bottom: 20px;
					}
				}
			}
		}
		.bottom{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px 0;
			.tit{
				cursor: pointer;
				&:hover{
					color: #FF3300 !important;
				}
			}
			.item{
				color: rgba(48, 55, 61, 0.60);
				font-size: 13px;
				line-height: 15px;
				padding-right: 20px;
				border-right: 1px solid rgba(48, 55, 61, 0.20);
				margin-right: 20px;
				&:last-child{
					margin-right: 0;
					border-right: none;
					display: flex;
					align-items: center;
					a{
						color: rgba(48, 55, 61, 0.60);
					}
					.tit{
						color: rgba(48, 55, 61, 0.60);
						margin-right: 20px;
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}
	}
</style>
